// COLORS
$global-color-branch: #ab47bc;
$global-color-primary: #3f51b5;
$global-color-success: #00ff00;
$global-color-error: #f00;

// COLORS TEXT
$global-color-text-branch: #565454;

// COLORS TEXT STATUS
$global-color-text-new: #ffbf00;
$global-color-text-approved: #1e7e34;
$global-color-text-rejected: #ed2553;
$global-color-text-pending: #ff7400;

// Font Family
$font-1: Lato, sans-serif;
$font-2: 'Poppins', sans-serif;

// text Colors ---------------
$white: #ffffff;
$black: #0b0b0b;
$brown: #444141;
// $brown: #353535;
$grey: #5f6977;
$dark2: #30393f;
$dark3: #22292d;
$red: #f6f7f8;
$green: #f6f7f8;
$blue: #57a6ff;
$text-color: #9affdd;

// bg Colors ---------------
$bg-white: #ffffff;
$bg-black: #0b0b0b;
$bg-grey: #f6f6f8;
$bg-purple: #f0e0ff;
$bg-footer: #333333;

// Heading Color
$heading-color: #000000;

// Theme Color
// $theme-color: #3f5eeb;
$theme-color: red;
$theme-dark: #1d2126;

// Other Color
// $accent-color: #9affdd;
// $icon-color: #0c66c2;
// $link-color: #4cd1a4;
// $link-color2: #36b389;
$accent-color: #b7eef3;
$icon-color: #0c66c2;
$link-color: #66b0b8;
$link-color2: #0eb8cf;
$link-color3: #6ee2f1;
$headerIcon: #afb0b1;
$headerNavToggle: #afb0b1;
$footerIcon: #757575;

// Responsive Variables
$xx-layout: 'only screen and (min-width: 1366px) and (max-width: 1600px)';
$xl-layout: 'only screen and (min-width: 1200px) and (max-width: 1365px)';
$lg-layout: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout: 'only screen and (min-width: 768px) and (max-width: 991px)';
$xs-layout: 'only screen and (max-width: 767px)';
$sm-layout: 'only screen and (min-width: 576px) and (max-width: 767px)';
$xxs-layout: 'only screen and (min-width: 320px) and (max-width: 479px)';
