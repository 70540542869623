.aboutme-page {
  .container {
    max-width: 1170px;
    margin: auto;
    position: relative;
    padding-left: 50px;
    padding-right: 50px;
  }
  .row {
    margin-left: 0;
    margin-right: 0;
  }

  @media (max-width: 1720px) {
    .container {
      max-width: 1260px;
      padding-left: 50px;
      padding-right: 50px;
    }
  }
  @media (max-width: 1200px) {
    .container {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
  @media (max-width: 760px) {
    .container {
      padding-left: 37px;
      padding-right: 37px;
    }
  }
}
