.about-me {
  font-family: Lato, sans-serif;
  cursor: -webkit-image-set(url("../../images/white-mouse.png") 2.5x) 10 10, auto;

  .container {
    // margin-left: 50px;
    // margin-right: 50px;
  }

  .img-cover,
  .content {
    padding: 0;
    // width: 100%;
  }

  .img-container {
    height: 500px;
    margin-right: 70px;
  }
  .img-cover {
    display: block;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .wrapper {
  }

  .section-name {
    color: #c23;
    font-family: Lato, sans-serif;
    font-size: 12px;
    letter-spacing: 3px;
    text-transform: uppercase;
    font-weight: bold;
  }

  h1 {
    font-size: 2.5rem;
    font-family: Lato, sans-serif;
    line-height: 1.4;
    margin: 20px 0 21px 0;
    font-weight: bold;
  }

  p {
    font-size: 18px;
    font-family: Lato, sans-serif;
    line-height: 2.1;
    letter-spacing: 0.2px;
  }

  .btn-rounded {
    border-radius: 50px;
    color: #fff;
    background: #000;
    border: none;
    display: block;

    font-size: 0.75rem;
    height: 55px;
    letter-spacing: 3px;
    line-height: 55px;
    text-transform: uppercase;
    text-align: center;
    width: 220px;

    &:hover {
      background: #c23;
      cursor: -webkit-image-set(url("../../images/black-mouse.png") 2.5x) 10 10, auto;
    }
  }
}

@media (max-width: 767px) {
  .section.about-me {
    padding: 50px 0 80px 0;
  }
  .about-me {
    .img-container {
      height: 400px;
      margin-right: 0;
    }

    h1 {
      font-size: 2rem;
    }
    .wrapper {
      margin-top: 30px;
      position: relative;
    }
  }
}

@media (min-width: 768px) and (max-width: 1139px) {
  .about-me {
    .img-container {
      height: 600px;
      margin-right: 40px;
    }
    h1 {
      font-size: 2.2rem;
    }
  }
}
