h1 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 52px;
  line-height: 1.15;
  font-weight: 500;
}

h2 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 32px;
  line-height: 1.25;
  font-weight: 600;
}

h3 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 22px;
  line-height: 1.35;
  font-weight: 500;
}

h4 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 17px;
  line-height: 1.5;
  font-weight: 600;
}

h5 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 1.15;
  font-weight: 500;
}

h6 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 1.15;
  font-weight: 500;
}

p {
  margin-bottom: 32px;
  line-height: 1.5;
}

i.icon {
  font-size: 22px;
  margin-right: 16px;
}
.icon:hover {
  // cursor: pointer;
  cursor: none;
  color: $icon-color;
}

a:hover {
  color: $link-color;
}

.text-link {
  color: $link-color;
}
.text-link2 {
  color: $link-color2;
}

.accent-container {
  position: relative;
  // display: inline-block;

  .accent {
    position: absolute;
    left: 10px;
    top: 11px;
    width: 100%;
    height: 16px;
    background-color: $accent-color;
    // background-color: #ffe985;
    z-index: -1;
  }
}

.section {
  padding: 80px 0px;

  .title-wrapper {
    text-align: center;
    // .title-desc {
    //   color: $grey;
    //   font-size: 16px;
    // }
  }
}

h2.title {
  position: relative;
  display: inline-block;
  z-index: 1;
}
h2.title::before {
  display: block;
  position: absolute;
  content: "";
  width: 100%;
  background-color: #eee;
  height: 3px;
  bottom: -5px;
}
h2.title::after {
  display: block;
  position: absolute;
  content: "";
  width: 30%;
  background-color: $link-color2;
  height: 3px;
  bottom: -5px;
}

.text {
  letter-spacing: 0.2px;
  font-size: 18px;
  // color: $brown;
}
.text-desc {
  color: $grey;
  font-size: 16px;
  // text-align: justify;
}

.bold {
  font-weight: 600;
}

.container {
  max-width: 1170px;
  margin: auto;
  position: relative;
  padding-left: 50px;
  padding-right: 50px;
}
.row {
  margin-left: 0;
  margin-right: 0;
  // padding-left: 0;
  // padding-right: 0;
}

@media (max-width: 1720px) {
  .container {
    max-width: 1720px;
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (max-width: 1200px) {
  .container {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (max-width: 760px) {
  .container {
    padding-left: 37px;
    padding-right: 37px;
  }
}
