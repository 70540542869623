.portfolio-detail {
  .image {
    width: 100%;

    img {
      width: 100%;
    }
  }

  .content {
    .text-link2 {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
