.portfolio-detail-page {
  .hero {
    .hero-img {
      height: 500px;
      h1 {
        font-size: 5rem;
      }
    }
  }

  .portfolio-content {
    cursor: -webkit-image-set(url('../../images/white-mouse.png') 2.5x) 10 10, auto;

    .project-header {
      margin-top: -5px;
      padding: 0;

      .project-services {
        color: #444;

        font-size: 0.75rem;
        letter-spacing: 2px;
        text-transform: uppercase;
      }
      .project-title {
        font-size: 2.25rem;
        font-weight: 900;
        letter-spacing: 2.7px;
        margin-top: 10px;
        text-transform: uppercase;
        padding-right: 20px;
      }
      .project-points {
        margin: 40px 0;

        h3 {
          padding-left: 18px;
          position: relative;

          color: #444;
          font-size: 18px;
          font-family: Lato, sans-serif;
          line-height: 2.1;
          letter-spacing: 0.2px;

          &:before {
            top: 16px;
            left: 1px;
            background-color: #090220;
            content: '';
            position: absolute;
            width: 5px;
            height: 5px;
            border-radius: 50%;
          }
        }
      }
    }

    .project-overview {
      padding: 0;
      p {
        color: #444;
        font-size: 19px;
        font-family: Lato, sans-serif;
        line-height: 2.1;
        letter-spacing: 0.2px;
      }
      .btn-rounded {
        border-radius: 50px;
        color: #fff;
        background: #000;
        border: none;
        display: block;
    
        font-size: 0.75rem;
        height: 55px;
        letter-spacing: 3px;
        line-height: 55px;
        text-transform: uppercase;
        text-align: center;
        width: 220px;
    
        &:hover {
          background: #c23;
          cursor: -webkit-image-set(url("../../images/black-mouse.png") 2.5x) 10 10, auto;
        }
      }
    }
  }

  .portfolio-images {
    margin-top: 50px;

    .project-img {
      margin-bottom: 10px;
      width: 100%;
      cursor: -webkit-image-set(url('../../images/white-mouse.png') 2.5x) 10 10, auto;
      // max-height: 700px;

      &.fullsize {
        margin: auto;
        width: 80% !important;
      }
    }
    .project-img-desc {
      margin-bottom: 70px;
      text-align: center;
      color: #444;
      font-size: 19px;
      font-family: Lato, sans-serif;
      line-height: 2.1;
      letter-spacing: 0.2px;
    }
    .video-container {
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    }
    .responsive-iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 80%;
    }   
  }

  @media (max-width: 390px) {
    .hero .hero-img {
      height: 300px;
    }
  }

  @media (max-width: 590px) {
    .section {
      padding: 40px 0;
    }
    .portfolio-content {
      .project-header {
        .project-services {
          font-size: 0.65rem;
        }
        .project-title {
          font-size: 1.75rem;
          padding-left: 0px;
        }
        .project-points {
          margin: 30px 0;
          h3 {
            font-size: 16px;
          }
        }
      }

      .project-overview {
        p {
          font-size: 17px;
          margin-bottom: 20px;
        }
      }
    }
    .portfolio-images {
      margin-top: 20px;
      .project-img-desc {
        margin-bottom: 30px;
      }
    }
    .hero {
      .hero-img {
        height: 350px;
        h1 {
          font-size: 3rem;
        }
      }
    }
  }
}
