.footer {
  background: #111;
  display: block;
  font-family: Lato, sans-serif;
  cursor: -webkit-image-set(url('../../images/white02-mouse.png') 2.5x) 10 10, auto;

  .footer_01 {
    display: flex;
    flex-direction: column;
    padding: 100px 0;

    h3.section-name {
      color: #c23;
      font-size: 0.75rem;
      letter-spacing: 3px;
      text-transform: uppercase;
      font-weight: bold;
    }

    a.mail-link {
      color: #fff;
      display: block;
      margin: 20px 0;
      font-size: 3rem;
      font-weight: 700;
      letter-spacing: -1px;

      &:hover {
        color: #c23;
        cursor: -webkit-image-set(url('../../images/white02-mouse.png') 2.5x) 10 10, auto;
      }
    }

    p {
      width: 60%;

      color: #ddd;
      font-size: 18px;
      letter-spacing: 1px;
      line-height: 2.1;
      font-weight: 300;
      letter-spacing: 0.4px;
      padding-bottom: 0;
    }
  }

  .footer_02 {
    min-height: 100px;
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    .copyright-info {
      letter-spacing: 0.5px;
      text-transform: capitalize;
      padding-left: 0;
      margin-bottom: 20px;

      p {
        font-size: 0.95rem;
        color: #ddd;
      }
    }

    .social-icons {
      text-align: end;

      .icons {
        color: #ddd;
        font-size: 16px;
        text-decoration: none;
        transition: all 0.2s ease-in-out;

        &:hover {
          color: #c23;
          cursor: -webkit-image-set(url('../../images/white02-mouse.png') 2.5x) 10 10, auto;
        }
      }
      .icons:nth-last-child(-n + 2) {
        margin-left: 45px;
      }
    }
  }
}
@media (max-width: 590px) {
  .footer {
    .footer_01 {
      padding: 100px 0;
      p {
        font-size: 1.125rem;
        width: 90%;
      }
      a.mail-link {
        font-size: 1.6rem;
      }
    }

    .footer_02 {
      padding-bottom: 50px;
    }
    .footer_02 .copyright-info {
      text-align: center;
    }
    .footer_02 .social-icons {
      text-align: center;

      .icons:nth-last-child(-n + 2) {
        margin-left: 30px;
      }
    }
  }
}
