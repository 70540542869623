.header {
  // background-color: white !important;
  background-color: white;
  position: sticky;
  top: 0;
  left: 0;
  // border-bottom: 1px solid #e0e0e0;
  // font-weight: 500;
  // text-transform: uppercase;
  // padding: 5px 0;
  z-index: 10;

  cursor: -webkit-image-set(url('../../images/black-mouse.png') 2.5x) 10 10, auto;

  a:hover {
    cursor: -webkit-image-set(url('../../images/black-mouse.png') 2.5x) 10 10, auto;
  }

  .row {
    height: 90px;
    display: flex;
    // flex-direction: column;
    justify-content: space-between;
    align-items: center;
    // padding: 0 24px;
  }
  .logo-container {
    // width: 100px;
    .logo {
      width: 100px;
    }
    .logo:hover {
      text-decoration: none;
    }
    a {
      padding: 10px;
    }
  }

  .navigation-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    a {
      font-size: 0.75rem;
      text-transform: uppercase;
      letter-spacing: 2.5px;
      

      text-decoration: none;
      transition: all 0.2s ease-in-out;

      margin-left: 35px;
      padding: 10px;
    }
    a:hover {
      color: #c23;
      cursor: -webkit-image-set(url('../../images/black-mouse.png') 2.5x) 10 10, auto;
    }

    // .nav-items {
    // }
    .nav-items-selected {
      color: #c23;
    }
    .nav-items:focus {
      color: #c23;
    }

    .open-nav-wrapper {
      position: relative;
      display: none;

      .open-nav {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        right: 0;
        height: 22px;
        width: 28px;
        z-index: 1;

        i {
          background: #000;
          position: absolute;
          top: 50%;
          right: 0;
          height: 2px;
          width: 100%;
          transition: all 0.3s ease-in-out;
        }

        i:before {
          position: absolute;
          top: -9px;
          content: ' ';
          height: 2px;
          width: 100%;
          transition: all 0.3s ease-in-out;

          background: #000;
        }

        i:after {
          position: absolute;
          top: 9px;
          content: ' ';
          height: 2px;
          width: 100%;
          transition: all 0.3s ease-in-out;

          background: #000;
        }
      }

      .open-nav:hover {
        // cursor: pointer;
        cursor: -webkit-image-set(url('../../images/black-mouse.png') 2.5x) 10 10, auto;
      }

      .open-nav:hover i:before,
      .open-nav:hover i:after {
        width: 80%;
      }
    }
  }

  @media (max-width: 800px) {
    .navigation-container {
      .navigation {
        display: none;
      }

      .open-nav-wrapper {
        display: inline-block;
      }
    }
  }
}

.mobile-container {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  width: 450px;

  .modal-content {
    border: none;
    border-radius: 0;
    // width: 100%;
    padding: 50px 50px;

    .icon-wrapper {
      text-align: end;
      margin-bottom: 50px;

      .icon {
        font-size: 27px;
        margin-left: auto;
        &:hover {
          // cursor: pointer;
          cursor: -webkit-image-set(url('../../images/black-mouse.png') 2.5x) 10 10, auto;
          color: #c23;
        }
      }
    }

    .navigation {
      display: flex;
      flex-direction: column;

      a {
        display: block;
        font-size: 2.25rem;
        font-weight: 900;
        letter-spacing: 0.3px;
        text-transform: capitalize;
        margin-bottom: 18px;

        &:hover {
          color: #c23;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .mobile-container {
    width: 100%;

    .modal-content {
      padding: 40px;
    }
  }
}
