@import '~bootstrap/scss/bootstrap.scss';
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.0/font/bootstrap-icons.css');
// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap&family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

@import 'variables';
@import 'settings';
@import 'settings.dark';
@import 'common';

@import './pages/home.page';
// @import './pages/photography.page';
// @import './pages/blogs.page';
// @import './pages/blogDetail.page';
@import './pages/aboutme.page';
@import './pages/portfolio.page';
@import './pages/portfolio-detail.page';

@import './components/header.component';
@import './components/footer.component';
// @import './components/stacks.component';
@import './components/about.component';
@import './components/hero.component';
@import './components//portfolio.component';
@import './components//portfolioDetail.component';
// @import './components//customButton.component';
// @import './components//ScrollTopButton.component';
