.portfolio {
  min-height: 650px;
  &-filter {
    .item {
      margin: 0 14px;
      font-size: 15px;
      font-weight: 500;
      font-family: 'Poppins', Helvetica, sans-serif;
      // color: 'red';
      &:hover {
        cursor: pointer;
        color: $link-color;
      }
      &:active {
        color: $link-color;
      }
    }
    .item-focus {
      margin: 0 14px;
      font-size: 15px;
      color: $link-color;
      font-weight: 600;
      font-family: 'Poppins', Helvetica, sans-serif;
      &:hover {
        cursor: pointer;
      }
    }
  }
  &-card {
    width: 100%;
    text-align: center;

    img {
      width: 100%;
      height: 100%;
      // min-height: 260px;
      object-fit: contain;
      border-radius: 10px;
    }

    .imageBox {
      position: relative;
      transition: all 3s ease-in-out;
    }

    .imageBox .hoverImg {
      position: absolute;
      left: 0;
      top: 0;
      display: none;
    }

    .imageBox .imageBox-tag {
      color: rgb(46, 46, 46);
      position: absolute;
      padding: 6px 15px;
      right: -3px;
      top: -5px;
      z-index: 5;
      background-color: #ffe985;
    }

    .imageBox:hover .hoverImg {
      display: block;
      transition: transform 5s ease;
      cursor: pointer;
    }

    .content {
      .text-wrapper {
        width: 90%;
        margin: 0 auto;
        .text-desc {
          // text-align: justify;
          text-align: left;
          // letter-spacing: 0.3px;
          min-height: 7rem;
          font-size: 16px;
        }
      }
      p {
        margin-bottom: 10px !important;
      }
    }
  }
}

@media only screen and (max-width: 1200px) and (min-width: 992px) {
  .portfolio {
    &-card {
      .content {
        min-height: 160px;
      }
    }
  }
}
