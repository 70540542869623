.hero {
  font-family: Lato, sans-serif;
  cursor: -webkit-image-set(url('../../images/white-mouse.png') 2.5x) 10 10, auto;

  .hero-img {
    // background: url(..//img/hero-image.jpg);
    // background: url('https://moquin.octrace.com/assets//img/hero-image.jpg');
    -webkit-background-size: cover;
    background-size: cover;
    object-fit: cover;
    background-position: center;
    position: relative;
    text-align: center;
    height: 800px;
    width: 100%;

    .content {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      width: 100%;
    }

    h1 {
      font-size: 6rem;
      color: #fff;
      text-transform: uppercase;
      letter-spacing: 25px;
      font-weight: bold;
    }

    h2 {
      font-size: 0.875rem;
      color: #fff;
      font-weight: 400;
      letter-spacing: 3.5px;
      line-height: 2.5;
      text-transform: uppercase;
      margin-top: 10px;
    }
  }

  .about {
    h2 {
      font-size: 2.25rem;
      font-weight: 400;
      line-height: 1.8;
      letter-spacing: 0.4px;
      text-align: center;
      margin: 120px auto 130px auto;
      width: 670px;
    }
  }
}

@media (max-width: 390px) {
  .hero .hero-img {
    height: 300px;
  }
}

@media (max-width: 590px) {
  .hero {
    .hero-img {
      height: 400px;
      h1 {
        font-size: 3rem;
        letter-spacing: 3px;
      }
      h2 {
        font-size: 0.75rem;
        line-height: 2;
        letter-spacing: 2.5px;
      }
    }
    .about {
      h2 {
        font-size: 1.3rem;
        margin: 80px auto 90px auto;
        width: 100%;
      }
    }
  }
}
