.portfolio-page {
  cursor: -webkit-image-set(url('../../images/white-mouse.png') 2.5x) 10 10, auto;

  .portfolio-grid {
    padding: 0 0 100px 0;
    .portfolio-item {
      position: relative;
      // height: 400px;
      padding: 0;
      overflow: hidden;

      a {
        height: 400px;
      }

      .img-cover {
        display: block;
        object-fit: cover;
        height: 100%;
        width: 100%;
      }

      .portfolio-item-overlay {
        // display: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        transition: all 0.3s linear;

        .portfolio-item-info {
          margin: 50px 0 0 50px;

          .portfolio-item-category {
            font-size: 0.75rem;
            letter-spacing: 2.5px;
            text-transform: uppercase;
            transition: all 0.3s linear;

            span {
              margin-right: 13px;
              position: relative;
              transition: all 0.3s linear;
            }
            span:not(:first-child) {
              padding-left: 16px;
              &:before {
                top: 6px;
                left: 0;
                background-color: #090220;
                content: '';
                position: absolute;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                transition: all 0.3s linear;
              }
            }
          }

          .portfolio-item-title {
            font-size: 2rem;
            letter-spacing: 0.5px;
            text-transform: capitalize;
            transition: all 0.3s linear;
            margin-top: 3px;
          }
        }
      }

      &:hover .portfolio-item-overlay {
        background: rgba(195, 32, 49, 0.9);
        cursor: -webkit-image-set(url('../../images/white-mouse.png') 2.5x) 10 10, auto;
      }
      &:hover .portfolio-item-category,
      &:hover .portfolio-item-title {
        color: rgba(255, 255, 255, 1);
      }
      &:hover .portfolio-item-info .portfolio-item-category span:not(:first-child):before {
        background-color: rgba(255, 255, 255, 1);
      }
    }
  }
  // .container {
  //   max-width: 1170px;
  //   margin: auto;
  //   position: relative;
  //   padding-left: 50px;
  //   padding-right: 50px;
  // }
  // .row {
  //   margin-left: 0;
  //   margin-right: 0;
  // }

  // @media (max-width: 1720px) {
  //   .container {
  //     max-width: 1260px;
  //     padding-left: 50px;
  //     padding-right: 50px;
  //   }
  // }
  // @media (max-width: 1200px) {
  //   .container {
  //     padding-left: 50px;
  //     padding-right: 50px;
  //   }
  // }
  // @media (max-width: 760px) {
  //   .container {
  //     padding-left: 37px;
  //     padding-right: 37px;
  //   }
  // }
}
