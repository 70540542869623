body.dark-mode {
  color: $white;
  background-color: $theme-dark;
  transition: all 0.1s linear;

  h1,
  h2,
  h5,
  h6 {
    color: $white;
  }

  h3,
  h4 {
    color: #e8e8e8;
  }
  .portfolio-filter h4.item {
    color: #f5f5f5;
  }
  .portfolio-grid h4 {
    color: $link-color3;
  }
  .greeting-text {
    color: $link-color3;
  }

  p {
    color: #d0d5da;
  }

  .accent {
    background-color: $theme-dark;
  }

  .stacks {
    background-color: $theme-dark;

    &-card {
      background-color: $dark3;
    }
  }

  .blog-detail-page {
    .blog-content {
      .text {
        p,
        span,
        a {
          color: $white;
        }
      }
    }
  }

  .blog-card {
    background-color: $dark3;

    &:hover {
      background-color: #1e2122;
    }
  }

  header.header {
    background-color: $dark2;
    border-bottom-color: $dark2;
  }

  .footer {
    background-color: $dark3;
  }

  .icon {
    color: $white;
  }
  .icon.dark {
    color: #ffc700;
  }
  .icon.bi-linkedin {
    color: #5195da;
  }
  .icon.bi-github {
    color: #a98ae7;
  }

  a {
    color: $white;
  }
  .logo {
    color: $white;
  }
  .nav-items:hover {
    color: #57a6ff;
  }
  .nav-items:focus {
    color: #57a6ff;
  }
  .text-link {
    color: $link-color3;
  }
  .text-link2 {
    color: $link-color;
  }

  h2.title::before {
    background-color: #656565;
    height: 2px;
  }
  h2.title::after {
    width: 20%;
    height: 2px;
  }

  .custom-button {
    // background-color: #1f6feb;
    color: #f5faff;
  }

  .imageBox {
    position: relative;
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($dark3, 0.5);
      z-index: 1;

      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 70px;

      .plus:after {
        content: '+';
      }
    }
    .overlay:hover {
      background-color: rgba(rgb(255, 255, 255), 0);

      .plus:after {
        content: '';
      }
    }
  }
}
